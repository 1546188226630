<template>
    <Overlay v-if="isLoading" />
    <h5 v-if="!hideHeader">{{ $t('Import') }}</h5>

    <div class="d-flex flex-wrap gap-2 min-h-unset" v-bind="$attrs">
        <div class="btn-group" role="group">
            <button class="btn btn-outline-primary dropdown-toggle btn-sm" data-bs-toggle="dropdown">
                {{$t('Download Template')}}
            </button>
            <ul class="dropdown-menu">
                <li v-if="dataObject.allowInsert"><button class="dropdown-item" @click="downloadTemplate('Import')">{{$t('Download Import Template')}}</button></li>
                <li v-if="dataObject.allowUpdate"><button class="dropdown-item" @click="downloadTemplate('Update')">{{$t('Download Update Template')}}</button></li>
            </ul>
        </div>
        <!--<FileImportButton :disabled="!dataObject.allowInsert || !dataObject.allowUpdate" :dataObject="dataObject" class="btn btn-outline-primary btn-sm"
            accept="'xlsx'" disableChunks @error="()=>alert($t('There was an error in one or more rows when uploading.')+'\n'+$t('View DataLog for more information.'))">
            {{$t('Upload From Excel')}}
        </FileImportButton>-->
        <ImportTemplateBtn  :disabled="!dataObject.allowInsert || !dataObject.allowUpdate" :dataObject="dataObject" class="btn btn-outline-primary btn-sm" :batch = "gridRef.importDataBatch" :openDataLog = "openDataLog">
            {{$t('Upload From Excel')}}
        </ImportTemplateBtn>
        <button class="btn btn-outline-primary btn-sm" @click="openDataLog()">{{$t('View DataLog')}}</button>
    </div>
   <ImortDataLogModal ref="imortDataLogModalRef" :dataObject = "dataObject"/>
</template>

<script setup>
import { Overlay } from 'o365.vue.components.jsx';

import { ExportConfig } from 'o365.modules.exportData.ts';
import DataColumn from 'o365.controls.DataGrid.Column.ts';
//import FileImportButton from 'o365.vue.components.FileImportButton.vue';
import { ref, reactive, computed } from 'vue';

import ImortDataLogModal from 'o365.vue.components.ImortDataLogModal.vue';
import ImportTemplateBtn from 'o365.vue.components.ImportTemplateBtn.vue';

const props = defineProps({
    dataObject: null,
    gridRef: null,
    columns:null,
    hideHeader: Boolean,
});


const isLoading = ref(false),
    exportConfig = reactive(new ExportConfig());
let dataObject = props.dataObject ? props.dataObject : props.gridRef.dataObject;
const imortDataLogModalRef = ref(null);


exportConfig.sheetName = dataObject?.viewName?.split('_')?.pop() ?? 'Excel Template';

const openDataLog = () =>{
    imortDataLogModalRef.value.modalRef.show();
}
const columns = computed(() => {
    const arr = [];
    if (props.gridRef || props.columns) {
        (props.columns??props.gridRef.dataColumns.columns).forEach(col => {
            const vField = dataObject.fields[col.name];
            if ((!col.name.startsWith("o365") && vField && !vField.joined) || col.required) {
                arr.push(new DataColumn({
                    name: col.name,
                    field: col.field,
                    colId: col.colId,
                    headerName: col.headerName,
                    caption: col.caption ?? col.name,
                    width: col._width,
                    dataType: vField.type,
                    format: col.format,
                    shown: col.shown,
                    hide: col.hide,
                    link: col.cellRenderer === "OLink" ? col.cellRendererParams : null,
                    summaryAggregate: col.summaryAggregate,
                    parentGroupId: col.parentGroupId,
                    required:col.required
                }));
            }
        });
    }
    return arr;
})



const populateExportConfig = (columns) => {
    const arr = [];
    for (const col of columns) {
        if (col.shown || col.required) {
            arr.push({
                name: col.name,
                field: col.field,
                colId: col.colId,
                headerName: col.headerName,
                caption: dataObject.fields[col.field].caption,
                width: col._width,
                dataType: dataObject.fields[col.field].type,
                maxLength: dataObject.fields[col.field].maxLength,
                format: col.format,
                shown: col.required || col.shown,
                hide: col.required?false:col.hide,
                link: col.cellRenderer === "OLink" ? col.cellRendererParams : null,
                summaryAggregate: col.summaryAggregate,
                parentGroupId: col.parentGroupId,
                required:col.required
            });
        }
    }
    return arr;
}

const downloadTemplate = async (templateType) => {
    isLoading.value = true;
 
    exportConfig.fileName = templateType + "_template_"+dataObject?.export.getFileName()??dataObject.viewName.split("_").pop();

    exportConfig.columns = populateExportConfig(columns.value);
    
    if (templateType === 'Update') {

        exportConfig.includeDataTypes = true;
        exportConfig.exportAsImportTemplate = false;
        exportConfig.exportAsUpdateTemplate = true;
        const uniqueField = exportConfig.columns.find(x=>x.name == dataObject.fields.uniqueField);
        if(!uniqueField){
            var vUniqueField = dataObject.fields[dataObject.fields.uniqueField];
            exportConfig.columns.unshift({
                name: vUniqueField.name,
                field: vUniqueField.name,
                headerName: vUniqueField.caption,
                caption: vUniqueField.caption,
                shown:true,
                hide:false,
                dataType: vUniqueField.type,
                maxLength: vUniqueField.maxLength,
                required:true
               
               
            })
        }else{
            uniqueField.required = true;
        }
    } else if (templateType === 'Import') {
        exportConfig.includeDataTypes = true;
        exportConfig.exportAsImportTemplate = true;

    }

    if (props.gridRef) {
        props.gridRef.dataObject.emit('BeforeExportTemplate', templateType, exportConfig);
    }

    await dataObject.export.exportData(exportConfig, null, 'xlsx').catch((error) => {
        console.log('Failed to export', error);
        isLoading.value = false;
    });

    isLoading.value = false;
}

</script>